*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

input, button, textarea, select {
  font: inherit;
}

body {
  background-color: var(--background-color);
  font-family: Work Sans, sans-serif;
  font-weight: 300;
}

.container.main .left > .top > h1, .container.main.single > h1, .container h1, .container h2, .container h3, .container h4 {
  font-family: Bitter, serif;
  font-weight: 100;
}

.container.projects > .list > .item, .container.projects > .list > .combo > .item, .container.work > .list > .item, .container.work > .list > .combo > .item, .container.main {
  box-shadow: 0 1.25rem 1.625rem -.3125rem var(--shadow-color), 0 .5rem .625rem -.375rem var(--shadow-color);
}

@media (max-width: 600px) {
  .container.projects > .list > .item, .container.projects > .list > .combo > .item, .container.work > .list > .item, .container.work > .list > .combo > .item, .container.main {
    box-shadow: 0 .75rem .8125rem -.1562rem var(--shadow-color), 0 .25rem .3125rem -.1875rem var(--shadow-color);
  }
}

:root {
  --background-color: #11121d;
  --main-color: #25da86;
  --foreground-color: #eeede2;
  --mix-color: #25da8680;
  --shadow-color: #0000004d;
  --container-gutter: 2rem;
  --link-font-weight: inherit;
}

@media (max-width: 600px) {
  :root {
    --container-gutter: 1rem;
  }
}

.light :root {
  --background-color: #eeede2;
  --foreground-color: #11121d;
  --main-color: #25da86;
  --mix-color: #25da86df;
  --shadow-color: #0000001a;
  --link-font-weight: 500;
}

@media (prefers-color-scheme: light) {
  :root {
    --background-color: #eeede2;
    --foreground-color: #11121d;
    --main-color: #25da86;
    --mix-color: #25da86df;
    --shadow-color: #0000001a;
    --link-font-weight: 500;
  }
}

body {
  align-items: center;
  gap: var(--container-gutter);
  padding-top: var(--container-gutter);
  padding-bottom: var(--container-gutter);
  flex-direction: column;
  display: flex;
}

.container {
  width: calc(100vw - var(--container-gutter) * 2);
  min-height: calc(100vh - var(--container-gutter) * 2);
  color: var(--foreground-color);
  padding: 2rem;
  font-size: 1.25rem;
}

@media (max-width: 600px) {
  .container {
    padding: 1.5rem;
  }
}

.container a:not(.icon) {
  color: var(--main-color);
  font-weight: var(--link-font-weight);
  padding: .125rem 0;
  text-decoration: none;
  position: relative;
}

.container a:not(.icon):after {
  content: "";
  height: 1px;
  width: 0;
  background-color: currentColor;
  transition: width .2s ease-in-out;
  display: block;
  position: absolute;
  bottom: 0;
  left: .1rem;
}

.light .container a:not(.icon):after {
  height: 2px;
}

@media (prefers-color-scheme: light) {
  .container a:not(.icon):after {
    height: 2px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .container a:not(.icon):after {
    transition: none;
  }
}

.container a:not(.icon):hover:after {
  width: calc(100% - .2rem);
}

.container a.icon {
  color: var(--main-color);
  transition: color .2s ease-in-out;
  display: contents;
}

.container a.icon:hover {
  color: var(--foreground-color);
}

.container a.icon > svg {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.container a.icon.small > svg {
  width: 16px;
  height: 16px;
}

.container h1, .container h2, .container h3, .container h4 {
  color: var(--main-color);
}

.container.main {
  border: 1px solid var(--mix-color);
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media (max-width: 600px) {
  .container.main {
    flex-direction: column;
    gap: 1rem;
  }
}

.container.main.single {
  justify-content: center;
  align-items: center;
}

.container.main.single > h1 {
  color: var(--main-color);
  margin-bottom: .5rem;
  font-size: clamp(3rem, 15vw, 6rem);
  line-height: 1;
}

.container.main .left {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.container.main .left > .top > h1 {
  color: var(--main-color);
  margin-bottom: .5rem;
  font-size: clamp(3rem, 15vw, 6rem);
  line-height: 1;
}

.container.main .left > .top > small {
  font-size: 1.25rem;
}

.container.main .left > nav > ul {
  flex-direction: column;
  gap: .125rem;
  display: flex;
}

.container.main .left > .bio {
  max-width: 31ch;
}

.container.main .right {
  align-self: end;
}

.container.main .right > nav > ul {
  flex-direction: column;
  align-items: end;
  display: flex;
}

@media (max-width: 600px) {
  .container.main .right > nav > ul {
    flex-direction: row;
    gap: 1rem;
  }

  .container.projects, .container.work {
    padding: 0;
  }
}

.container.projects h2, .container.work h2 {
  margin-bottom: .5rem;
  font-size: clamp(1.5rem, 10vw, 4rem);
  line-height: 1;
}

.container.projects > .list, .container.work > .list {
  max-width: 100ch;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: auto;
  padding: 1rem;
  display: flex;
}

@media (max-width: 600px) {
  .container.projects > .list, .container.work > .list {
    padding: 0;
  }
}

.container.projects > .list > .item, .container.projects > .list > .combo > .item, .container.work > .list > .item, .container.work > .list > .combo > .item {
  border: 1px solid var(--mix-color);
}

.container.projects > .list > .item > .head, .container.projects > .list > .combo > .item > .head, .container.work > .list > .item > .head, .container.work > .list > .combo > .item > .head {
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  line-height: 1;
  display: flex;
}

.container.projects > .list > .item > small, .container.projects > .list > .combo > .item > small, .container.work > .list > .item > small, .container.work > .list > .combo > .item > small {
  color: var(--mix-color);
  font-weight: var(--link-font-weight);
  margin-bottom: .5rem;
}

.container.projects > .list > .item, .container.work > .list > .item {
  flex: 100%;
  padding: 2rem;
}

@media (max-width: 600px) {
  .container.projects > .list > .item, .container.work > .list > .item {
    padding: 1.5rem;
  }
}

.container.projects > .list > .item > small, .container.work > .list > .item > small {
  display: block;
}

.container.projects > .list > .item > p, .container.projects > .list > .item > ul, .container.work > .list > .item > p, .container.work > .list > .item > ul {
  font-size: .75em;
}

.container.projects > .list > .item > ul > li, .container.work > .list > .item > ul > li {
  margin-left: 3ch;
  list-style-type: " - ";
}

.container.projects > .list > .combo, .container.work > .list > .combo {
  width: 100%;
  grid-template-columns: repeat(6, minmax(20ch, 1fr));
  gap: 1rem;
  font-size: .875rem;
  display: grid;
}

@media (max-width: 600px) {
  .container.projects > .list > .combo, .container.work > .list > .combo {
    grid-template-columns: repeat(2, minmax(20ch, 1fr));
  }
}

.container.projects > .list > .combo > .item, .container.work > .list > .combo > .item {
  grid-column: span 2 / span 2;
  padding: 1.5rem;
}

.container.projects > .list > .combo > .item:first-child, .container.work > .list > .combo > .item:first-child {
  grid-column: 2 / span 2;
}

@media (max-width: 600px) {
  .container.projects > .list > .combo > .item:first-child, .container.work > .list > .combo > .item:first-child {
    grid-column: span 2 / span 2;
  }
}

.container.projects > .list > .combo > .item > .head, .container.work > .list > .combo > .item > .head {
  margin-bottom: .5rem;
}

.container.contact {
  flex-direction: column;
  display: flex;
}

.container.contact h2 {
  margin-bottom: .5rem;
  font-size: clamp(1.5rem, 10vw, 4rem);
  line-height: 1;
}

.container.contact .box {
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container.contact .box p {
  max-width: 72ch;
  text-align: center;
}

#backsplash {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

/*# sourceMappingURL=index.45c4fa6e.css.map */
