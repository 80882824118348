*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

html:focus-within {
    scroll-behavior: smooth;
}

body {
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
    font: inherit;
}

@mixin light-theme {
    @at-root {
        .light & {
            @content;
        }

        @media (prefers-color-scheme: light) {
            & {
                @content;
            }
        }
    }
}

@mixin sm {
    @at-root {
        @media (max-width:600px) {
            & {
                @content;
            }
        }
    }
}

body {
    background-color: var(--background-color);
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
}

%header-font {
    font-family: 'Bitter', serif;
    font-weight: 100;

    // @include light-theme {
    //     font-weight: 400;
    // }
}


%general-shadow {
    box-shadow: 0 1.25rem 1.625rem -0.3125rem var(--shadow-color), 0 0.5rem 0.625rem -0.375rem var(--shadow-color);

    @include sm {
        box-shadow: 0 0.75rem 0.8125rem -0.1562rem var(--shadow-color), 0 0.25rem 0.3125rem -0.1875rem var(--shadow-color);
    }
}

:root {
    --background-color: rgb(17, 18, 29);
    --main-color: hsl(152, 71%, 50%);
    --foreground-color: #{invert(rgb(17, 18, 29))};
    --mix-color: #{change-color(hsl(152, 71%, 50%), $alpha: 0.5)};
    --shadow-color: rgba(0, 0, 0, 0.3);
    --container-gutter: 2rem;
    --link-font-weight: inherit;

    @include sm {
        --container-gutter: 1rem;
    }


    @include light-theme {
        --background-color: #{invert(rgb(17, 18, 29))};
        --foreground-color: rgb(17, 18, 29);
        --main-color: hsl(152, 71%, 50%);
        --mix-color: #{change-color(hsl(152, 71%, 50%), $alpha: 0.875)};
        --shadow-color: rgba(0, 0, 0, 0.1);
        --link-font-weight: 500;
    }
}

$container-gutter: var(--container-gutter);

body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--container-gutter);
    padding-top: var(--container-gutter);
    padding-bottom: var(--container-gutter);
}

.container {
    width: calc(100vw - var(--container-gutter) * 2);
    min-height: calc(100vh - var(--container-gutter) * 2);
    font-size: 1.25rem;
    color: var(--foreground-color);
    padding: 2rem;

    @include sm {
        padding: 1.5rem;
    }

    a:not(.icon) {
        color: var(--main-color);
        padding: 0.125rem 0;
        text-decoration: none;
        position: relative;
        font-weight: var(--link-font-weight);

        &:after {
            background-color: currentColor;
            content: '';
            display: block;
            height: 1px;
            width: 0;
            position: absolute;
            bottom: 0;
            left: 0.1rem;
            transition: width 0.2s ease-in-out;

            @include light-theme {
                height: 2px;
            }

            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }

        &:hover:after {
            width: calc(100% - 0.2rem);
        }
    }

    a.icon {
        display: contents;
        color: var(--main-color);
        transition: color 0.2s ease-in-out;

        &:hover {
            color: var(--foreground-color);
        }

        &>svg {
            width: 24px;
            height: 24px;
            stroke: currentColor;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: none;
        }

        &.small>svg {
            width: 16px;
            height: 16px;
        }
    }

    h1,
    h2,
    h3,
    h4 {
        @extend %header-font;
        color: var(--main-color);
    }


    &.main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        border: 1px solid var(--mix-color);
        @extend %general-shadow;

        @include sm {
            flex-direction: column;
            gap: 1rem;
        }

        &.single {
            justify-content: center;
            align-items: center;

            &>h1 {
                font-size: clamp(3rem, 15vw, 6rem);
                margin-bottom: 0.5rem;
                line-height: 1;
                @extend %header-font;
                color: var(--main-color);
            }
        }

        .left {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &>.top {
                &>h1 {
                    font-size: clamp(3rem, 15vw, 6rem);
                    margin-bottom: 0.5rem;
                    line-height: 1;
                    @extend %header-font;
                    color: var(--main-color);
                }

                &>small {
                    font-size: 1.25rem;
                }
            }

            &>nav>ul {
                display: flex;
                flex-direction: column;
                gap: 0.125rem;
            }

            &>.bio {
                max-width: 31ch;

            }
        }

        .right {
            align-self: end;

            &>nav>ul {
                display: flex;
                flex-direction: column;
                align-items: end;

                @include sm {
                    flex-direction: row;
                    gap: 1rem;
                }
            }

        }
    }

    &.projects,
    &.work {
        @include sm {
            padding: 0;
        }

        h2 {
            font-size: clamp(1.5rem, 10vw, 4rem);
            margin-bottom: 0.5rem;
            line-height: 1;
        }

        &>.list {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            gap: 2rem;
            max-width: 100ch;
            margin: auto;

            @include sm {
                padding: 0;
            }

            &>.item,
            &>.combo>.item {
                border: 1px solid var(--mix-color);
                @extend %general-shadow;

                &>.head {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.5rem;
                    line-height: 1;
                }

                &>small {
                    color: var(--mix-color);
                    margin-bottom: 0.5rem;
                    font-weight: var(--link-font-weight);
                }
            }

            &>.item {
                padding: 2rem;
                flex: 1 1 100%;

                @include sm {
                    padding: 1.5rem;
                }

                &>small {
                    display: block;
                }

                &>p,
                &>ul {
                    font-size: 0.75em;
                }

                &>ul>li {
                    list-style-type: ' - ';
                    margin-left: 3ch;
                }
            }

            &>.combo {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(6, minmax(20ch, 1fr));
                font-size: 0.875rem;
                gap: 1rem;

                @include sm {
                    grid-template-columns: repeat(2, minmax(20ch, 1fr));
                }

                &>.item {
                    grid-column: span 2 / span 2;
                    padding: 1.5rem;

                    &:first-child {
                        grid-column: 2 / span 2;
                    }

                    @include sm {
                        &:first-child {
                            grid-column: span 2 / span 2;
                        }
                    }

                    &>.head {
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }

    &.contact {
        display: flex;
        flex-direction: column;

        h2 {
            font-size: clamp(1.5rem, 10vw, 4rem);
            margin-bottom: 0.5rem;
            line-height: 1;
        }

        .box {
            flex: 1 1 auto;
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                max-width: 72ch;
                text-align: center;
            }
        }
    }
}

#backsplash {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
}
